<script>
import { getReports } from "@/api/reports";

export default {
  name: "Reports",
  data() {
    return {
      loading: true,
      products: [],
      orders: [],
    };
  },
  async mounted() {
    const { products, order_ids } = await getReports();
    this.products = Object.values(products).sort((a, b) => {
      if (a.title < b.title) {
        return -1;
      }
      if (a.title > b.title) {
        return 1;
      }
      return 0;
    });

    this.orders = order_ids.reduce((acc, order) => {
      if (!acc[order.type]) {
        acc[order.type] = [];
      }
      acc[order.type].push(order.code);
      return acc;
    }, {});
    this.loading = false;
  },
};
</script>

<template>
  <div>
    <h1 class="font-semibold text-xl mb-5">
      Отчеты
      <i v-if="loading" class="el-icon-loading"></i>
    </h1>

    <el-table border size="small" :data="products">
      <el-table-column type="index" label="N" align="center"></el-table-column>
      <el-table-column prop="title" label="Название" sortable></el-table-column>
      <el-table-column
        prop="count_kaspi"
        label="Количество kaspi"
        align="right"
      ></el-table-column>
      <el-table-column
        prop="count_self"
        label="Количество своя"
        align="right"
      ></el-table-column>
      <el-table-column label="Количество общее" align="right">
        <template slot-scope="{ row }">{{
          row.count_kaspi + row.count_self
        }}</template>
      </el-table-column>
    </el-table>

    <ul>
      <li v-for="(value, key) in orders" :key="key" class="mt-5">
        {{ key }} ({{ value.length }}) <br />
        {{ value }}
      </li>
    </ul>
  </div>
</template>

<style scoped></style>
