<template>
  <div>
    <h1 class="mb-10 flex justify-between items-center">
      <div class="text-xl font-semibold">
        Отчеты
        <i v-if="loading" class="el-icon-loading"></i>
      </div>
      <div class="flex justify-end">
        <el-select
          class="mr-2"
          filterable
          clearable
          v-model="filters.city_id"
          placeholder="Выберите город"
          @change="getAnalytics"
        >
          <el-option
            v-for="c in cities"
            :key="c.id"
            :label="c.name"
            :value="c.id"
          ></el-option>
        </el-select>

        <div class="mr-2">
          <el-date-picker
            @change="getAnalytics"
            v-model="filters.date"
            type="daterange"
            align="right"
            range-separator="-"
            value-format="dd.MM.yyyy"
            format="dd.MM.yyyy"
            start-placeholder="Дата с"
            end-placeholder="Дата по"
          >
          </el-date-picker>
        </div>
        <el-upload
          :action="uploadUri"
          :headers="headers"
          :show-file-list="false"
          :on-success="handleUploadSuccess"
          :before-upload="handleBeforeUpload"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          :data="{ type: 'file' }"
          class="mr-2"
        >
          <el-button :disabled="loading" ref="upload" plain type="warning">
            Загрузить данные kaspi
          </el-button>
        </el-upload>

        <el-upload
          :action="uploadUri"
          :headers="headers"
          :show-file-list="false"
          :on-success="handleUploadSuccessJusan"
          :before-upload="handleBeforeUpload"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          :data="{ type: 'file' }"
          class="mr-2"
        >
          <el-button :disabled="loading" ref="upload" plain type="primary">
            Загрузить данные jusan
          </el-button>
        </el-upload>

        <el-button
          @click="download"
          plain
          type="success"
          :icon="loading ? 'el-icon-loading' : 'el-icon-download'"
          :disabled="loading"
        >
          Скачать
        </el-button>
      </div>
    </h1>

    <main>
      <div
        v-if="analytics && analytics.without_dealer_price.length > 0"
        class="my-2"
      >
        Уходы без звкупочной цены: {{ analytics.without_dealer_price }}
      </div>

      <div v-if="returned.length" class="my-2">
        <h3>Возвраты:</h3>
        <ul>
          <li v-for="r in returned" :key="r.id">
            <router-link
              :to="{
                name: 'accounting-item',
                params: { id: r.id },
                query: { type: 'outcome' },
              }"
              target="_blank"
              class="text-blue-500 hover:underline"
              >{{ r.basis }}</router-link
            >
          </li>
        </ul>
      </div>

      <table v-if="analytics" border="1">
        <tr>
          <th></th>
          <th
            v-for="marketplace in analytics.marketplaces"
            :key="marketplace.name"
          >
            {{ marketplace.name }}
          </th>
        </tr>
        <tr>
          <td class="left">Кол-во заказов</td>
          <td v-for="(marketplace, key) in analytics.marketplaces" :key="key">
            {{ marketplace.count }}
          </td>
        </tr>
        <tr>
          <td class="left">Доход</td>
          <td v-for="(marketplace, key) in analytics.marketplaces" :key="key">
            {{ numberFormat(marketplace.total) }}
          </td>
        </tr>
        <tr>
          <td class="left">Комиссия банка</td>
          <td v-for="(marketplace, key) in analytics.marketplaces" :key="key">
            {{ numberFormat(marketplace.tax) }}
          </td>
        </tr>
        <tr>
          <td class="left">Доставка</td>
          <td v-for="(marketplace, key) in analytics.marketplaces" :key="key">
            {{ numberFormat(marketplace.delivery) }}
          </td>
        </tr>

        <tr>
          <td class="left">Расход на товары</td>
          <td v-for="(marketplace, key) in analytics.marketplaces" :key="key">
            {{ numberFormat(marketplace.products_total) }}
          </td>
        </tr>
        <tr>
          <td class="left">Прибыль</td>
          <td
            v-for="(marketplace, key) in analytics.marketplaces"
            :key="key"
            class="bold"
          >
            {{ numberFormat(marketplace.profit) }}
          </td>
        </tr>
      </table>

      <ul class="mt-5">
        <li>Прибыль: {{ numberFormat(profit) }}</li>
        <li>
          Операционные расходы и доставка:
          {{ numberFormat(operating_expenses) }}
        </li>
        <li>Налоги: {{ numberFormat(taxes) }}</li>
        <li>Чистая прибыль: {{ numberFormat(net_profit) }}</li>
      </ul>
    </main>
  </div>
</template>

<script>
import {
  importKaspiPayAnalytics,
  getKaspiPayAnalytics,
  importJusanAnalytics,
} from "@/api/analytics";
import { getCities } from "@/api/branch";
import { format } from "date-fns";
import { numberFormat } from "@/helpers/formatter";
import LocalStorageService from "@/services/LocalStorageService";
const localStorageService = LocalStorageService.getService();

export default {
  name: "Analytics",
  marketplaceNames: {
    kaspi: "Kaspi",
    jusan: "Jusan",
    local: "Другие",
    total: "Общее",
  },
  data: () => ({
    filters: {
      date: [],
      city_id: null,
    },

    loading: false,
    uploadLoading: false,
    uploadUri: `${process.env.VUE_APP_DOMAIN}/api/upload`,

    analytics: null,
    cities: [],

    profit: 0,
    operating_expenses: 0,
    taxes: 0,
    net_profit: 0,
    returned: [],
  }),
  computed: {
    headers() {
      const token = localStorageService.getAccessToken();
      return { Authorization: `Bearer ${token}` };
    },
    user() {
      return this.$store.state.user;
    },
  },
  async created() {
    await this.getAnalytics();
    this.cities = await getCities();
  },
  methods: {
    async getAnalytics() {
      let filters = {
        city_id: this.filters.city_id,
      };
      if (this.filters.date.length > 0) {
        filters["start_date"] = this.filters.date[0];
        filters["stop_date"] = this.filters.date[1];
      } else {
        let start_date = new Date();
        // let stop_date = new Date();

        start_date = start_date.setDate(start_date.getDate() - 1);

        // start_date.setDate(16);
        start_date = format(start_date, "dd.MM.yyyy");

        // stop_date = format(stop_date, "dd.MM.yyyy");
        filters["start_date"] = start_date;
        filters["stop_date"] = start_date;
        this.filters.date = [start_date, start_date];
      }

      this.loading = true;
      const {
        profit,
        operating_expenses,
        taxes,
        net_profit,
        returned,
        ...others
      } = await getKaspiPayAnalytics(filters);

      this.analytics = others;
      this.profit = profit;
      this.operating_expenses = operating_expenses;
      this.taxes = taxes;
      this.net_profit = net_profit;
      this.returned = returned;
      this.loading = false;
    },
    async download() {
      let filters = {
        city_id: this.filters.city_id,
      };
      if (this.filters.date.length > 0) {
        filters["start_date"] = this.filters.date[0];
        filters["stop_date"] = this.filters.date[1];
      } else {
        let start_date = new Date();
        start_date.setDate(start_date.getDate() - 1);
        start_date = format(start_date, "dd.MM.yyyy");
        filters["start_date"] = start_date;
        filters["stop_date"] = start_date;
        this.filters.date = [start_date, start_date];
      }

      this.loading = true;
      const { url } = await getKaspiPayAnalytics({
        ...filters,
        action: "download",
      });

      console.log(url);

      this.$message.success("Успешно");
      window.open(`https://api.alasal.kz${url}`, "_blank");
      this.loading = false;
    },
    numberFormat(number) {
      return numberFormat(Math.round(number));
    },
    handleBeforeUpload() {
      this.uploadLoading = true;
    },
    async handleUploadSuccess(res) {
      try {
        const result = await importKaspiPayAnalytics(res.url);
        this.uploadLoading = false;
        if (result.success) {
          this.$message.success("Успешно");
          await this.getAnalytics();
        } else {
          this.$message.error(`Ошибка ${result.message}`);
        }
      } catch (e) {
        this.$message.error(`Ошибка ${e.message}`);
      }
    },
    async handleUploadSuccessJusan(res) {
      try {
        const result = await importJusanAnalytics(res.url);
        this.uploadLoading = false;
        if (result.success) {
          this.$message.success("Успешно");
          await this.getAnalytics();
        } else {
          this.$message.error(`Ошибка ${result.message}`);
        }
      } catch (e) {
        this.$message.error(`Ошибка ${e.message}`);
      }
    },
    openFileDialog() {
      this.$nextTick(() => {
        this.$refs.upload.$el.click();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
table {
  width: 100%;
  border: 1px;
  tr {
    td,
    th {
      border: 1px solid #d9d9d9;
      text-align: right;
      padding: 5px;
      &:first-child {
        width: 250px;
        font-weight: bold;
      }
      &.left {
        text-align: left;
      }
      &.bold {
        font-weight: bold;
      }
    }
  }
}
</style>
