<script>
import LocalStorageService from "@/services/LocalStorageService";
import {
  importSulpak,
  getSulpakProducts,
  linkProducts,
} from "@/api/accounting";
import { numberFormat } from "@/helpers/formatter";
const localStorageService = LocalStorageService.getService();

export default {
  name: "Sulpak",
  data() {
    return {
      total: 0,
      products: [],
      selectedProduct: null,
      filters: { page: 1, limit: 30 },

      uploadLoading: false,
      uploadUri: `${process.env.VUE_APP_DOMAIN}/api/upload`,
    };
  },
  computed: {
    headers() {
      const token = localStorageService.getAccessToken();
      return { Authorization: `Bearer ${token}` };
    },
  },
  async mounted() {
    await this.getProducts();
  },
  methods: {
    async getProducts() {
      const { data, total } = await getSulpakProducts(this.filters);
      this.products = data.map((p) => ({
        ...p,
        profit: p.kaspi_price * 0.88 - p.dealer_price,
      }));
      this.total = total;
    },
    handleBeforeUpload() {
      this.uploadLoading = true;
    },
    async handleUploadSuccess(res) {
      try {
        this.uploadLoading = false;
        const result = await importSulpak(res.url);

        if (result.success) {
          this.$message.success("Успешно");
        } else {
          this.$message.error(`Ошибка ${result.message}`);
        }
      } catch (e) {
        this.$message.error(`Ошибка ${e.message}`);
      }
    },
    numberFormat(number) {
      return numberFormat(number);
    },
    openProductDetails(row) {
      this.selectedProduct = row;
    },
    selectKaspiProduct(row) {
      console.log("row", row);
      this.selectedProduct.kaspi_sku = row.kaspi_sku;
      this.selectedProduct.kaspi_price = row.price;
    },
    async linkProducts() {
      if (!this.selectedProduct.kaspi_sku) {
        this.$message.error("Введите Kaspi SKU");
        return;
      }
      const data = await linkProducts(this.selectedProduct.id, {
        action: "link",
        kaspi_sku: this.selectedProduct.kaspi_sku,
        price: this.selectedProduct.kaspi_price,
      });

      if (data.success) {
        const product = this.products.find(
          (product) => product.id === this.selectedProduct.id
        );

        product.kaspi_sku = this.selectedProduct.kaspi_sku;
        product.kaspi_price = this.selectedProduct.kaspi_price;
        product.profit =
          this.selectedProduct.kaspi_price * 0.88 - product.dealer_price;
        product.verified = true;

        this.selectedProduct = null;
      }
    },
    tableRowClassName({ row }) {
      const { dealer_price, kaspi_price } = row;
      if (!kaspi_price) return "";
      const diff = kaspi_price * 0.88 - dealer_price;
      if (diff > 4000 && diff < 5000) {
        return "bg-yellow";
      } else if (diff > 5000) {
        return "bg-green";
      }
      return "";
    },
  },
};
</script>

<template>
  <div>
    <div class="flex items-center justify-between mb-5">
      <div class="font-semibold text-lg">Sulpak ({{ total }})</div>
      <el-upload
        :action="uploadUri"
        :headers="headers"
        :show-file-list="false"
        :on-success="handleUploadSuccess"
        :before-upload="handleBeforeUpload"
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        :data="{ type: 'file' }"
      >
        <el-button
          size="small"
          :disabled="uploadLoading"
          ref="upload"
          plain
          type="warning"
        >
          Загрузить прайс
        </el-button>
      </el-upload>
    </div>

    <el-table
      :data="products"
      border
      size="small"
      @row-click="openProductDetails"
      :row-class-name="tableRowClassName"
      :default-sort="{ prop: 'profit', order: 'descending' }"
    >
      <el-table-column
        type="index"
        width="50"
        align="center"
        label="N"
      ></el-table-column>
      <el-table-column label="Бренд" prop="brand" width="100"></el-table-column>
      <el-table-column label="Название" prop="name"></el-table-column>
      <el-table-column
        label="Кол-во"
        prop="quantity"
        width="100"
        align="right"
      ></el-table-column>
      <el-table-column
        label="Цена"
        prop="dealer_price"
        align="right"
        width="150"
      >
        <template slot-scope="{ row }">
          {{ numberFormat(row.dealer_price) }}
        </template>
      </el-table-column>
      <el-table-column
        label="Цена в каспи"
        prop="kaspi_price"
        align="right"
        width="150"
      ></el-table-column>
      <el-table-column
        label="Прибыль"
        prop="profit"
        align="right"
        width="150"
        sortable
      >
        <template slot-scope="{ row }">
          {{ numberFormat(row.profit) }}
        </template>
      </el-table-column>
      <el-table-column
        label="Код товара"
        prop="external_id"
        width="150"
      ></el-table-column>
      <el-table-column
        label="Артикул"
        prop="kaspi_sku"
        width="100"
      ></el-table-column>
      <el-table-column label="Verified" width="100" align="center">
        <template slot-scope="{ row }">
          <el-switch
            v-model="row.verified"
            active-color="#13ce66"
            inactive-color="#ff4949"
            :active-value="true"
            :inactive-value="false"
            disabled
          ></el-switch>
        </template>
      </el-table-column>
    </el-table>

    <el-drawer
      size="800px"
      :visible="!!selectedProduct"
      :before-close="() => (selectedProduct = null)"
      :with-header="false"
    >
      <template v-if="selectedProduct">
        <header
          class="flex justify-between items-center bg-gray-50 border-0 border-b border-solid border-gray-200 p-5 py-3.5 mb-5 font-semibold leading-5 text-lg"
        >
          <div>
            <div>
              {{ selectedProduct.brand }} | {{ selectedProduct.external_id }}
            </div>
            <div>{{ selectedProduct.name }}</div>
          </div>
        </header>

        <div class="px-5 mb-5 flex gap-x-2.5">
          <el-input
            v-model="selectedProduct.kaspi_sku"
            size="small"
            placeholder="Kaspi SKU"
          ></el-input>
          <el-input
            v-model.number="selectedProduct.kaspi_price"
            type="number"
            size="small"
            placeholder="Kaspi цена"
          ></el-input>
          <el-button @click="linkProducts" size="small" type="primary" plain
            >Сохранить привязку</el-button
          >
        </div>

        <el-table
          v-if="selectedProduct.options && selectedProduct.options.products"
          size="small"
          stripe
          :data="selectedProduct.options.products"
          @row-click="selectKaspiProduct"
        >
          <el-table-column
            type="index"
            width="50"
            align="center"
          ></el-table-column>
          <el-table-column prop="title" label="Название"></el-table-column>
          <el-table-column
            prop="kaspi_sku"
            label="Артикул"
            width="100"
          ></el-table-column>
          <el-table-column
            prop="price"
            label="Цена дилер"
            align="right"
            width="100"
          >
            <template>{{ selectedProduct.dealer_price }}</template>
          </el-table-column>
          <el-table-column
            prop="price"
            label="Цена каспи"
            align="right"
            width="100"
          ></el-table-column>
          <el-table-column
            prop="price"
            label="Прибыль"
            align="right"
            width="100"
          >
            <template slot-scope="{ row }">{{
              numberFormat(row.price * 0.88 - selectedProduct.dealer_price)
            }}</template>
          </el-table-column>
          <el-table-column
            prop="price"
            label="Выбор"
            align="center"
            width="100"
          >
            <template slot-scope="{ row }">
              <i
                class="el-icon-circle-check text-green-500"
                v-if="row.kaspi_sku === selectedProduct.kaspi_sku"
              ></i>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </el-drawer>
  </div>
</template>

<style lang="scss">
.bg-green {
  background-color: #f0f9eb !important;
}
.bg-yellow {
  background-color: #fffbe6 !important;
}
</style>
