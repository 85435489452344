<template>
  <div>
    <ul class="flex flex-col gap-y-5">
      <li v-for="(value, hour) in summary" :key="hour">
        <div class="font-medium">{{ hour }}</div>
        <ul class="ml-4">
          <li
            v-for="(count, status) in value"
            :key="status"
            class="flex items-center"
          >
            <div>
              {{ `${status}. ${$options.statusMap[status]}` || status }} -
              {{ count }}
            </div>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
import { getAnalytics } from "@/api/analytics";
import { format, setDate } from "date-fns";
import LocalStorageService from "@/services/LocalStorageService";
const localStorageService = LocalStorageService.getService();

export default {
  name: "Analytics",
  statusMap: {
    94: "Предзаказ",
    95: "Отправлен в ЛК",
    96: "Офферы найдены",
    97: "Офферы не найдены",
    98: "Без статуса",
    99: "Не требует обновления цены",
    200: "Цена обновлена успешно",
    401: "Ошибка авторизации",
    429: "Превышен лимит запросов",
  },
  data: () => ({
    summary: null,
    filters: {
      date: null,
    },
    loading: true,
  }),
  computed: {
    headers() {
      const token = localStorageService.getAccessToken();
      return { Authorization: `Bearer ${token}` };
    },
  },
  async created() {
    await this.getAnalytics();
  },
  methods: {
    async getAnalytics() {
      let filters = {};
      if (this.filters.date) {
        filters["start_date"] = this.filters.date[0];
        filters["stop_date"] = this.filters.date[1];
      } else {
        const start_date = format(setDate(new Date(), 1), "dd.MM.yyyy");
        const stop_date = format(new Date(), "dd.MM.yyyy");
        filters["start_date"] = start_date;
        filters["stop_date"] = stop_date;
        this.filters.date = [start_date, stop_date];
      }
      const loading = this.$loading({
        lock: true,
        text: "Загрузка",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const result = await getAnalytics(filters);
      this.summary = result.analytics;
      console.log(result);
      loading.close();
    },
    numberFormat(number) {
      return new Intl.NumberFormat().format(Math.round(number));
    },
  },
};
</script>

<style lang="scss" scoped>
.CANCELLED {
  color: #f56c6c;
  background: #fef0f0;
  border-color: #fbc4c4;
}
.COMPLETED {
  color: #67c23a;
  background: #f0f9eb;
  border-color: #c2e7b0;
}
.RETURNED {
  color: #909399;
  background: #f4f4f5;
  border-color: #d3d4d6;
}
</style>
